import { FC, useEffect, useMemo, useState } from "react";
import { useForm, UseFormSetError } from "react-hook-form";
import { isUndefined, noop } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IOTPFormData } from "../../boundary/forms/IOTPFormData";
import { getAuthService } from "../../diContainer/getDependencies";
import {
  Box,
  Button,
  Grid2 as Grid,
  Typography,
  Link,
  Tooltip,
  Stack,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { OtpInput } from "./form/OtpInput";

const DISPLAY_LAST_NUMBERS_QUANTITY = 4;
const RESEND_SMS_TIMEOUT = 30;

interface Props {
  onSendOtpClick: (
    otp: string,
    setError: UseFormSetError<IOTPFormData>
  ) => void;
  onResendActivationCode?: () => void;
  phoneNumber: string;
  isLoading: boolean;
  isSkipable?: boolean;
  onSkipStep?: () => void;
  onEditPhoneNumber?: () => void;
}

export const ValidateOtp: FC<Props> = ({
  onSendOtpClick,
  phoneNumber,
  onResendActivationCode,
  isLoading,
  isSkipable = false,
  onSkipStep,
  onEditPhoneNumber,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "signIn.validateOtp",
  });

  const navigate = useNavigate();

  const authService = getAuthService();

  const [resendInProgress, setResendInProgress] = useState(true);
  const [timer, setTimer] = useState<number | null>(RESEND_SMS_TIMEOUT);
  const phoneNumberSlice = useMemo(() => {
    const phoneLength = phoneNumber.length;

    if (phoneLength < DISPLAY_LAST_NUMBERS_QUANTITY) return phoneNumber;

    const startIdx = phoneLength - DISPLAY_LAST_NUMBERS_QUANTITY;

    return phoneNumber.slice(startIdx, phoneLength);
  }, [phoneNumber]);

  useEffect(() => {
    let interval: NodeJS.Timeout;

    if (resendInProgress && timer !== null) {
      interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer ? prevTimer - 1 : 0));
      }, 1000);
    }

    if (timer === 0) {
      setResendInProgress(false);
      setTimer(null);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [resendInProgress, timer]);

  const hasCodeResend = !isUndefined(onResendActivationCode);
  const handleResendCode = hasCodeResend
    ? () => {
        setResendInProgress(true);
        setTimer(RESEND_SMS_TIMEOUT);

        onResendActivationCode && onResendActivationCode();
      }
    : noop;

  const handleChangePhoneNumber = () => {
    if (onEditPhoneNumber) {
      onEditPhoneNumber();
    } else {
      authService.removeTokens();
      navigate(-1);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
  } = useForm<IOTPFormData>({
    mode: "onChange",
  });

  const onSubmit = (data: IOTPFormData) => {
    onSendOtpClick(data.otp, setError);
  };

  return (
    <Grid container spacing={3}>
      <Grid size={12}>
        <Typography component="h1" variant="h4" align="center">
          {t("header")}
        </Typography>
      </Grid>
      <Grid size={12}>
        <Typography
          variant="subtitle1"
          sx={{ textWrap: "initial" }}
          gutterBottom
        >
          <Trans
            t={t}
            i18nKey="text"
            values={{ phoneNumberSlice }}
            components={{ span: <span className="no-wrap" /> }}
          />
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Button
            onClick={handleChangePhoneNumber}
            color="primary"
            sx={{ padding: 0 }}
          >
            {t("changePhoneNumber")}
          </Button>

          {hasCodeResend && (
            <Tooltip title={t("noCode")}>
              <Button
                variant="outlined"
                disabled={resendInProgress}
                onClick={handleResendCode}
              >
                {t("resendCode")}
              </Button>
            </Tooltip>
          )}
        </Box>
      </Grid>
      <Grid size={12}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <OtpInput
            // @ts-ignore
            control={control}
          />
        </form>
        {resendInProgress && (
          <Stack
            mt={1}
            direction="row"
            sx={{ justifyContent: "space-between" }}
          >
            <Typography variant="body2">{t("waitResend")}</Typography>
            <Typography variant="body2" fontWeight="bold">
              {timer}
            </Typography>
          </Stack>
        )}
      </Grid>
      <Grid size={12} alignContent="space-around">
        <LoadingButton
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid}
          variant="contained"
          fullWidth
          loading={isLoading}
        >
          {t("buttonLabel")}
        </LoadingButton>
      </Grid>
      {isSkipable && (
        <Grid size={12} justifyContent="flex-end">
          <Link component="button" onClick={onSkipStep}>
            {t("notReceivingOtp")}
          </Link>
        </Grid>
      )}
    </Grid>
  );
};
