import { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { Skeleton, useMediaQuery, useTheme } from "@mui/material";
import { getProfileService } from "../../../diContainer/getDependencies";
import { StyledPromoList } from "./styledComponents";
import PromoCodeCard from "./PromoCodeCard";

const PromoCodeList: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const profileService = getProfileService();

  const profileData = useObservable(profileService.data$);

  if (!profileData || profileData?.loading) {
    // if (true) {
    return (
      <Skeleton
        animation="wave"
        variant="rounded"
        width="100%"
        height={isMobile ? 140 : 179}
        sx={{ m: { md: "1rem 0 2rem" }}}
      />
    );
  }

  if (!profileData?.promoCodes?.length) {
    return null;
  }

  return (
    <StyledPromoList>
      {profileData?.promoCodes.map((promoCode) => (
        <li key={promoCode.id}>
          <PromoCodeCard promoCode={promoCode} />
        </li>
      ))}
    </StyledPromoList>
  );
};

export default PromoCodeList;
