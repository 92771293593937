import { Box, Container } from "@mui/material";
import React, { FC } from "react";
import { Footer } from "./Footer";

interface IProps {
  children?: React.ReactNode;
}
export const LayoutHeadless: FC<IProps> = ({ children }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{ padding: { xs: "0 2rem" } }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </Box>
      </Container>
      <Footer showLangSelector />
    </Box>
  );
};
