import { FC } from "react";
import { Box, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LayoutDelicate from "../../layouts/LayoutDelicate";

export const RegisterCompanySuccess: FC = ({}) => {

  return (
    <LayoutDelicate>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        gap={1}
        my={5}
      >
        <Typography variant="h5" mb={2}>Дякуємо за реєстрацію!</Typography>
        <CheckCircleIcon
          sx={{
            fontSize: 58,
            color: "#FAB72B",
            mb: 2
          }}
        />
        <Typography variant="body1" align="center" gutterBottom>
          Очікуйте на дзвінок від нашого менеджера протягом доби.
        </Typography>
      </Box>
    </LayoutDelicate>
  );
};
