import { useTranslation } from "react-i18next";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import ProfileSettingsCard from "../../components/ProfileSettings/ProfileSettingsCard";
import ProfileEmailForm from "../../components/ProfileSettings/ProfileEmailForm";

const EmailSettingPage = () => {
  const { t } = useTranslation("translation");

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <ProfileSettingsCard
        title={t("profile.form.updateEmailTitle") ?? ""}
        backUrl="/settings/profile"
      >
        <ProfileEmailForm />
      </ProfileSettingsCard>
    </SettingsLayout>
  );
};

export default EmailSettingPage;
