import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { ICompanyForm, companyFormConfig, initialValues } from "./fields";
import FormBuilder from "../../form/FormBuilder";
import LayoutDelicate from "../../layouts/LayoutDelicate";
import { ISurveyFormPayload } from "../../../../boundary/forms/IFreeSurveyFormData";
import { clearPhoneNumber } from "../../../../utils/utils";

interface IProps {
  submitForm: (formData: ISurveyFormPayload) => void;
}

const RegisterCompanyForm: FC<IProps> = ({ submitForm }) => {
  const [searchParams] = useSearchParams();
  const agentId = searchParams.get("agentId");

  const handleSubmit = (formData: ICompanyForm) => {
    const surveyAnswers = Object.entries(formData).map(([key, value]) => ({
      name: key,
      value: key === "companyPhone" ? clearPhoneNumber(value) : value,
    }));

    const companySurvey = {
      surveyDescription: "Delikat company registration form",
      surveyData: [
        {
          questionDescription: "Company data",
          questionAnswers: [
            ...surveyAnswers,
            {
              name: "smartLinkId",
              value: agentId,
            },
          ],
        },
      ],
    };

    submitForm(companySurvey);
  };

  return (
    <LayoutDelicate>
      <Box maxWidth={500} mx="auto">
        <Typography component="h1" variant="h4" textAlign="center" mb={3}>
          {"Юридична особа"}
        </Typography>
        <Typography variant="h6">{"Введіть дані для реєстрації: "}</Typography>
        <FormBuilder<ICompanyForm>
          isLoading={false}
          initialValues={initialValues}
          submitLabel={"Подати заявку"}
          fieldsConfig={companyFormConfig}
          onSave={handleSubmit}
        />
      </Box>
    </LayoutDelicate>
  );
};

export default RegisterCompanyForm;
