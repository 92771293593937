import { templateIdsByCampaign } from "../domain/specs/templateIdsByCampaign";

export const getTemplateIdByCampaign = (campaign?: string): number | undefined => {
  if (campaign && campaign in templateIdsByCampaign) {
    return templateIdsByCampaign[
      campaign as keyof typeof templateIdsByCampaign
    ];
  }
  return undefined;
};
