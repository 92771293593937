import { FC, ReactElement } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { Container } from "@mui/material";
import { getIssuerConfigService } from "../../../diContainer/getDependencies";
import Header from "./Header";
import Profile from "../Profile";

interface IProps {
  title?: string;
  children?: ReactElement;
}

export const ProfileLayout: FC<IProps> = ({ title, children }) => {
  const issuerConfigService = getIssuerConfigService();

  const issuerConfigData = useObservable(issuerConfigService.data$);

  return (
    <>
      <Header
        heading={title ?? issuerConfigData?.config?.companyName}
        showMenu
      />

      <Container component="main">
        <Profile>{children}</Profile>
      </Container>
    </>
  );
};

export default ProfileLayout;
