import { FC } from "react";
import { useTheme, useMediaQuery } from "@mui/material";
import PromoCodeInfoPopover from "./PromoCodeInfoPopover";
import PromoCodeTabs from "./PromoCodeTabs";
import PromoCodeList from "./PromoCodeList";

const PromoCodeBox: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {isMobile ? (
        <PromoCodeTabs />
      ) : (
        <>
          <PromoCodeInfoPopover />
          <PromoCodeList />
        </>
      )}
    </>
  );
};

export default PromoCodeBox;
