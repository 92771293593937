import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import FailDispatchIcon from "@mui/icons-material/NewReleases";
import { useQuery } from "../../../adapters/hooks/useQuery";
import {
  getIssuerConfigService,
  getRouterService,
} from "../../../diContainer/getDependencies";
import { getActionError } from "../../../utils/action-results";
import IssuerToken from "../../../domain/specs/issuerToken";
import { LayoutHeadless } from "../../components/layouts/LayoutHeadless";
import { DemoatomicActionError } from "../../components/actionComponents/demoatomic/DemoatomicActionError";

export const ActionErrorPage = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action.dispatchResult",
  });
  const issuerConfigService = getIssuerConfigService();
  const routerService = getRouterService();

  const location = useLocation();
  const query = useQuery();
  const errorBase64 = query.get("err");
  const { actionType, errorCode } = location.state || {};
  const issuerSysName = issuerConfigService.getIssuer();

  const { textKey, titleKey, buttonTextKey } = useMemo(
    () => getActionError({ errorBase64, actionType, errorCode }),
    [errorBase64, actionType, errorCode]
  );

  const handleClose = () => {
    // actionService.clearAction();
    routerService.navigateToIssuerPath("/");
  };

  if (issuerSysName === IssuerToken.Demoatomic) {
    return <DemoatomicActionError />;
  }

  return (
    <LayoutHeadless>
      <Box display="flex" flexDirection="column" alignItems="center" gap={2}>
        <FailDispatchIcon sx={{ fontSize: "50px" }} />
        <Typography variant="h6" gutterBottom align="center">
          {t(titleKey)}
        </Typography>
        <Typography gutterBottom align="center">
          <Trans i18nKey={textKey} components={{ br: <br /> }} t={t} />
        </Typography>
        <Button variant="contained" onClick={handleClose} sx={{ mt: "10px" }}>
          {t(buttonTextKey)}
        </Button>
      </Box>
    </LayoutHeadless>
  );
};
