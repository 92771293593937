import { FC } from "react";
import ReactDOM from "react-dom";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  open: boolean;
  title: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmationDialog: FC<IProps> = ({
  open,
  title,
  onConfirm,
  onCancel,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });

  const dialogContent = (
    <Dialog
      open={open}
      keepMounted
      aria-labelledby="confirmation-dialog-title"
      onClose={onCancel}
      sx={{ "& .MuiPaper-root": { margin: 0, borderRadius: 2 } }}
    >
      <DialogTitle
        id="confirmation-dialog-title"
        sx={{
          p: 2,
          pt: 5,
          width: "340px",
          textAlign: "center",
        }}
      >
        {title}
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button color="success" onClick={onConfirm}>
          {t("yes")}
        </Button>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Button color="error" onClick={onCancel}>
          {t("no")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  return ReactDOM.createPortal(dialogContent, document.body);
};

export default ConfirmationDialog;
