import { FC } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { Container, CssBaseline } from "@mui/material";
import { isInAppBrowser } from "../../utils/utils";
import { ThemeProvider } from "../ThemeProvider";
import Loader from "./layouts/Loader";
import InAppBrowserWarning from "./InAppBrowserWarning";

export const App: FC = () => {
  const navigation = useNavigation();

  if (isInAppBrowser()) {
    return (
      <ThemeProvider>
        <CssBaseline />
        <Container sx={{ paddingY: 2 }}>
          <InAppBrowserWarning />
        </Container>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider>
      <CssBaseline />
      {navigation.state === "loading" ? <Loader /> : <Outlet />}
    </ThemeProvider>
  );
};
