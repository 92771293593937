import {
  Box,
  BoxProps,
  Card,
  CardProps,
  styled,
} from "@mui/material";

export const StyledPromoList = styled("ul")(({}) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  "@media screen and (min-width: 600px)": {
    flexDirection: "row",
    width: "calc(100% + 4rem)",
    overflowX: "auto",
    scrollbarWidth: "thin",
    padding: "1rem 2rem 2rem",
    margin: "0 -2rem -2rem",
  },
}));

export const StyledPromoCard = styled(Card)<CardProps>(({}) => ({
  width: "100%",
  padding: "1rem 1rem 1.5rem",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  justifyContent: "space-between",
  borderRadius: "12px",
  boxShadow: "0px -1px 8px 0px rgba(0,0,0,0.15)",
  "@media screen and (min-width: 600px)": {
    width: 494,
    minHeight: 179, 
  },
}));

export const StyledTabPanel = styled(Box)<BoxProps>(() => ({
  padding: '1rem 0',
  backgroundColor: "#E6E6E6",
  [`@media (min-width:600px)`]: {
    backgroundColor: "#F8F8F8",
  },
}));
