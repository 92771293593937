import { useTranslation } from "react-i18next";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import ProfileSettingsCard from "../../components/ProfileSettings/ProfileSettingsCard";
import MenuMobile from "../../components/layouts/MenuMobile";

export const SettingsPage = () => {
  const { t } = useTranslation("translation", { keyPrefix: "header" });

  return (
    <SettingsLayout title={t("settings") ?? ""}>
      <ProfileSettingsCard backUrl="/">
        <MenuMobile />
      </ProfileSettingsCard>
    </SettingsLayout>
  );
};
