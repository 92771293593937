import { useParams } from "react-router-dom";
import IssuerToken from "../../../domain/specs/issuerToken";
import { PositiveRetailateResult } from "../../components/retailate/PositiveRetailateResult";
import { DemoatomicActionSuccess } from "../../components/actionComponents/demoatomic/DemoatomicActionSuccess";
import { RegisterCompanySuccess } from "../../components/actionComponents/delicate/RegisterCompanySuccess";
import { NotFound } from "../../components/NotFound";

export const PositiveResultPage: React.FC = () => {
  const { issuerSysName } = useParams();

  if (issuerSysName?.toLowerCase() === IssuerToken.Retailete) {
    return <PositiveRetailateResult />;
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Demoatomic) {
    return <DemoatomicActionSuccess />;
  }

  if (
    [IssuerToken.DelikatDev, IssuerToken.Delikat].includes(
      issuerSysName?.toLowerCase() as IssuerToken
    )
  ) {
    return <RegisterCompanySuccess />;
  }

  return <NotFound />;
};
