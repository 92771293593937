import { Box } from "@mui/material";
import { Link } from "../common/Link";
import { useTranslation } from "react-i18next";
import { SUPPORT_FORM_URL } from "../../../domain/specs/support";
import LanguageMenu from "./LanguageMenu";

interface IProps {
  showLangSelector?: boolean;
}

export const Footer: React.FC<IProps> = ({
  showLangSelector = false
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "footer" });

  return (
    <Box
      sx={{
        p: "24px",
        mt: "auto",
        textAlign: "center",
      }}
      component="footer"
    >
      {showLangSelector && <LanguageMenu />}
      <Link to={SUPPORT_FORM_URL} target="_blank">
        {t("help")}
      </Link>
    </Box>
  );
};
