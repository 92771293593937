import { FC } from "react";
import { Button, styled } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useTranslation } from "react-i18next";
import { getRouterService } from "../../../diContainer/getDependencies";

interface IProps {
  backUrl?: string;
}

const StyledBackButton = styled(Button)(({ theme }) => ({
  padding: "0.5rem 0",
  color: "inherit",
  fontSize: "1.25rem",
  display: "flex",
  gap: "1rem",
  alignItems: "center",
  "&:hover": {
    color: theme.palette.primary.main,
  },
  "@media screen and (min-width: 600px)": {
    fontSize: "1rem",
  },
}));

const BackButton: FC<IProps> = ({ backUrl }) => {
  const { t } = useTranslation("translation", { keyPrefix: "settings" });

  const routerService = getRouterService();

  const handleGoBack = () => {
    routerService.navigateToIssuerPath(backUrl ?? "/");
  };

  return (
    <StyledBackButton variant="text" onClick={handleGoBack}>
      <ArrowBackIosNewIcon fontSize="small" />
      {t("back")}
    </StyledBackButton>
  );
};

export default BackButton;
