import { StateSubject } from "@roketus/web-toolkit";
import {
  IProfileInfoResponseAttributes,
  ISDK,
} from "@roketus/loyalty-end-user-js-sdk";
import {
  IUpdateProfileService,
  IUpdateProfileServiceData,
} from "../../boundary/IUpdateProfileService";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import { createDispatchError } from "../helpers";
import { getErrorTextFromResponse } from "../../utils/response-errors";
import {
  getProfileService,
  getRouterService,
} from "../../diContainer/getDependencies";

const dispatchError = createDispatchError("updateProfileService");

const stateMachine = new StateSubject<IUpdateProfileServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  isEditMode: false,
  isActivationMode: false,
  phoneNumber: null,
  email: null,
});

export const init = function (sdk: ISDK): IUpdateProfileService {
  const clearState: IUpdateProfileService["clearState"] = () => {
    stateMachine.setState({
      loading: false,
      isLoaded: false,
      failed: false,
      phoneNumber: null,
      email: null,
    });
  };

  const setEditMode: IUpdateProfileService["setEditMode"] = (isEditMode) => {
    stateMachine.setState({ isEditMode });
  };

  const setPhoneNumber: IUpdateProfileService["setPhoneNumber"] = (
    phoneNumber
  ) => {
    stateMachine.setState({ phoneNumber });
  };

  const resendActivationCode: IUpdateProfileService["resendActivationCode"] =
    async () => {
      pushLogToFaro("Profile: resend OTP for activation");

      stateMachine.setState({
        loading: true,
      });

      try {
        await sdk.phone.resendActivationCode().result;

        pushLogToFaro("Success: resend OTP for activation");

        stateMachine.setState({
          loading: false,
        });
      } catch (e) {
        pushErrorToFaro(e, {
          action: "resendActivationCode",
          message: "Error: failed to resend OTP for activation",
        });

        stateMachine.setState({
          loading: false,
        });
      }
    };

  const verifyProfile = async () => {
    pushLogToFaro("Profile: verifying profile");

    const routerService = getRouterService();

    try {
      resendActivationCode();

      routerService.navigateToIssuerPath("/settings/phone/activation");
    } catch (e) {
      pushErrorToFaro(e, {
        action: "veryfyProfile",
        message: "Error: failed to verify profile",
      });
    }
  };

  const activateProfileByOTP: IUpdateProfileService["activateProfileByOTP"] =
    async (code, setSuccess, setError) => {
      pushLogToFaro("Profile: send OTP");

      stateMachine.setState({
        loading: true,
      });

      const profileService = getProfileService();

      try {
        const { data: profile } = await sdk.phone.checkActivationCode(
          parseInt(code, 10)
        ).result;
        pushLogToFaro("Success: profile activation");

        profileService.updateProfileState(profile);

        stateMachine.setState({
          loading: false,
        });

        setSuccess();
      } catch (e) {
        pushErrorToFaro(e, {
          action: "activateProfileByOTP",
          message: "Error: failed to activate profile with OTP",
        });

        stateMachine.setState({
          loading: false,
        });

        const errorText = getErrorTextFromResponse(e);

        setError("otp", { message: errorText, type: errorText });
      }
    };

  const updateProfileInfo: IUpdateProfileService["updateProfileInfo"] = async (
    fields,
    setSuccess,
    setError
  ) => {
    stateMachine.setState({
      loading: true,
    });

    const profileService = getProfileService();

    try {
      const { data: profile } = await sdk.profile.updateProfile(
        fields.firstName,
        fields.lastName
      ).result;

      profileService.updateProfileState(
        profile as IProfileInfoResponseAttributes
      );

      stateMachine.setState({
        loading: false,
      });

      setSuccess();
    } catch (e: any) {
      pushErrorToFaro(e, {
        action: "updateProfileInfo",
        message: "Error: failed to update profile info",
      });

      stateMachine.setState({
        loading: false,
      });

      const errorText = getErrorTextFromResponse(e);

      setError("firstName", { message: errorText, type: errorText });
    }
  };

  const updatePhone: IUpdateProfileService["updatePhone"] = async (
    fields,
    setError
  ) => {
    const routerService = getRouterService();

    stateMachine.setState({
      loading: true,
      phoneNumber: fields.phone,
    });

    try {
      await sdk.phone.changePhone(fields.phone).result;

      stateMachine.setState({
        loading: false,
      });

      routerService.navigateToIssuerPath("/settings/phone/activation");
    } catch (e: any) {
      pushErrorToFaro(e, {
        action: "updatePhone",
        message: "Error: failed to update phone number",
      });

      stateMachine.setState({
        loading: false,
      });

      const errorText = getErrorTextFromResponse(e);

      setError("phone", { message: errorText, type: errorText });
    }
  };

  const updateEmail: IUpdateProfileService["updateEmail"] = async (
    fields,
    setSuccess,
    setError
  ) => {
    stateMachine.setState({
      loading: true,
    });

    const profileService = getProfileService();

    try {
      const { data: profile } = await sdk.email.changeEmail(fields.email).result;

      profileService.updateProfileState(
        profile as IProfileInfoResponseAttributes
      );

      stateMachine.setState({
        loading: false,
      });

      setSuccess();
    } catch (e: any) {
      pushErrorToFaro(e, {
        action: "updateEmail",
        message: "Error: failed to update email",
      });

      stateMachine.setState({
        loading: false,
      });

      const errorText = getErrorTextFromResponse(e);

      setError("email", { message: errorText, type: errorText });
    }
  };

  return {
    data$: stateMachine.state$,
    clearState,
    setEditMode,
    setPhoneNumber,
    verifyProfile,
    resendActivationCode,
    activateProfileByOTP,
    updateProfileInfo,
    updatePhone,
    updateEmail,
  };
};
