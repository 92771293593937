import ProfileLayout from "../../components/layouts/ProfileLayout";
import CardList from "../../components/CardsBox/CardList";

export const ProfilePage = () => {
  return (
    <ProfileLayout>
      <CardList />
    </ProfileLayout>
  );
};
