import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import type { IPhoneForm } from "../../../boundary/forms/IProfileForm";
import { PhoneInput } from "../form/PhoneInput";

interface IProps {
  type?: string;
  label?: string;
  value?: string;
  shouldVerify?: boolean;
  isVerified?: boolean;
  onEdit?: () => void;
}

const ReadonlyField: FC<IProps> = ({
  type,
  label,
  value,
  shouldVerify,
  isVerified,
  onEdit,
  ...props
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "profile.form" });

  const fieldProps: TextFieldProps = {
    ...props,
    label: t(label as string) || "",
    fullWidth: true,
    margin: "normal",
    variant: "outlined",
    slotProps: {
      inputLabel: {
        shrink: true,
      },
      input: {
        readOnly: true,
        endAdornment: shouldVerify ? (
          <InputAdornment position="end">
            {isVerified ? (
              <CheckIcon color="success" />
            ) : (
              <CloseIcon color="error" />
            )}
          </InputAdornment>
        ) : null,
      },
    },
  };

  return (
    <Box display="flex" alignItems="center" gap={2}>
      {type === "phone" ? (
        <PhoneInput<IPhoneForm>
          {...fieldProps}
          name="phone"
          label={label}
          value={value}
          readOnly
        />
      ) : (
        <TextField {...fieldProps} value={value} />
      )}
      <IconButton onClick={onEdit}>
        <EditIcon />
      </IconButton>
    </Box>
  );
};

export default ReadonlyField;
