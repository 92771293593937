import { createBrowserRouter } from "react-router-dom";
import { withFaroRouterInstrumentation } from "@grafana/faro-react";
import { App } from "../../view/components/App";
import { ErrorPage } from "../../view/pages/ErrorPage/ErrorPage";
import { SignInPage } from "../../view/pages/signin/SignInPage";
import { ProfilePage } from "../../view/pages/profile/ProfilePage";
import { IIssuerConfigService } from "../../boundary/IIssuerConfigService";
import { ValidateOtpPage } from "../../view/pages/signin/ValidateOtpPage";
import { PersistentStorage } from "@roketus/web-toolkit";
import { SignUpPage } from "../../view/pages/signup/SignUpPage";
import { createAuthLoader } from "./loaders/authLoader";
import { createIssuerLoader } from "./loaders/issuerLoader";
import { createProfileLoader } from "./loaders/profileLoader";
import { createOldRouteRedirectLoader } from "./loaders/oldRouteRedirectLoader";
import { SignUpOTPPage } from "../../view/pages/signup/SignUpOTPPage";
import { SignupProfilePage } from "../../view/pages/signup/SignupProfilePage";
import { IProfileService } from "../../boundary/IProfileService";
import { NegativeResultPage } from "../../view/pages/survey/NegativeResultPage";
import { ActionPage } from "../../view/pages/action/ActionPage";
import { getActionsRoutes, getAnonymousActionsRoutes } from "./actionsRoutes";
import { LandingPage } from "../../view/pages/LandingPage";
import { SurveyActionForm } from "../../view/pages/survey/SurveyActionForm";
import { SurveyAnonymousForm } from "../../view/pages/survey/SurveyAnonymousFormPage";
import { PositiveResultPage } from "../../view/pages/survey/PositiveResultPage";
import { IActionService } from "../../boundary/IActionService";
import { IAuthService } from "../../boundary/IAuthService";
import { ActionSuccessPage } from "../../view/pages/action/ActionSuccessPage";
import { ActionErrorPage } from "../../view/pages/action/ActionErrorPage";
import { PhoneActivationPage } from "../../view/pages/profile/PhoneActivationPage";
import { SettingsPage } from "../../view/pages/profile/SettingsPage";
import { ProfileSettingPage } from "../../view/pages/profile/ProfileSettingPage";
import PhoneSettingPage from "../../view/pages/profile/PhoneSettingPage";
import EmailSettingPage from "../../view/pages/profile/EmailSettingPage";
import EmailVerificationPage from "../../view/pages/profile/EmailVerificationPage";
import ProfileInfoSettingPage from "../../view/pages/profile/ProfileInfoSettingPage";

export const createRouter = ({
  issuerConfigService,
  storage,
  profileService,
  actionService,
  authService,
}: {
  issuerConfigService: IIssuerConfigService;
  storage: PersistentStorage;
  profileService: IProfileService;
  actionService: IActionService;
  authService: IAuthService;
}) => {

  const browserRouter = createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          loader: createIssuerLoader(issuerConfigService, false),
        },

        {
          path: ":issuerSysName",
          children: [
            // PROFILE routes
            {
              // element: <ProfileLayout />,
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createProfileLoader(
                  issuerConfigService,
                  profileService,
                  authService
                )(props);
              },
              children: [
                // {
                //   element: <ProfileLayout />,
                //   children: [
                {
                  index: true,
                  element: <ProfilePage />,
                },
                //   ],
                // },
                {
                  path: "settings",
                  // children: [
                  //   {
                  //     element: <SettingsLayout />,
                  children: [
                    {
                      index: true,
                      element: <SettingsPage />,
                    },
                    {
                      path: "profile",
                      children: [
                        {
                          index: true,
                          element: <ProfileSettingPage />,
                        },
                        {
                          path: "info",
                          element: <ProfileInfoSettingPage />,
                        },
                      ],
                    },
                    {
                      path: "phone",
                      children: [
                        {
                          index: true,
                          element: <PhoneSettingPage />,
                        },
                        {
                          path: "activation",
                          element: <PhoneActivationPage />,
                        },
                      ],
                    },
                    {
                      path: "email",
                      children: [
                        {
                          index: true,
                          element: <EmailSettingPage />,
                        },
                        {
                          path: "verification",
                          element: <EmailVerificationPage />,
                        },
                      ],
                    },
                  ],
                  //   },
                  // ],
                },
              ],
            },

            // SIGNIN routes
            {
              path: "signin",
              children: [
                {
                  index: true,
                  element: <SignInPage />,
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createAuthLoader(
                      authService,
                      issuerConfigService,
                      true
                    )(props);
                  },
                },
                {
                  path: "validate-otp",
                  element: <ValidateOtpPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
              ],
            },
            {
              path: "signin", // smart-link routes with loader
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
              children: [...getActionsRoutes(actionService)],
            },

            // SIGNUP routes
            {
              path: "signup",
              children: [
                {
                  index: true,
                  element: <SignUpPage />,
                  loader: async (props) => {
                    await createIssuerLoader(issuerConfigService)(props);
                    return await createAuthLoader(
                      authService,
                      issuerConfigService,
                      true,
                      true
                    )(props);
                  },
                },
                {
                  path: "validate-otp",
                  element: <SignUpOTPPage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
                {
                  path: "profile",
                  element: <SignupProfilePage />,
                  loader: createIssuerLoader(issuerConfigService),
                },
              ],
            },
            // smart-link routes with loader
            {
              path: "signup",
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
              children: [...getActionsRoutes(actionService)],
            },

            {
              path: "survey",
              element: <SurveyActionForm />,
              loader: async (props) => {
                await createIssuerLoader(issuerConfigService)(props);
                return await createAuthLoader(
                  authService,
                  issuerConfigService
                )(props);
              },
            },
          ],
        },

        // Anonymous routes with issuer loader
        {
          path: ":issuerSysName",
          loader: createIssuerLoader(issuerConfigService),
          children: [
            {
              path: "landing",
              element: <LandingPage />,
            },
            {
              path: "free-survey",
              element: <SurveyAnonymousForm />,
            },
            {
              path: "success",
              element: <PositiveResultPage />,
            },
            {
              path: "info",
              element: <NegativeResultPage />,
            },

            {
              path: "action",
              children: [
                {
                  index: true,
                  element: <ActionPage />,
                },
                {
                  path: "success",
                  element: <ActionSuccessPage />,
                },
                {
                  path: "info",
                  element: <ActionErrorPage />,
                },
              ],
            },
            // Anonymous smart-link routes with loader
            ...getAnonymousActionsRoutes(actionService),
          ],
        },

        // Routes for redirect from old pathes
        {
          path: "signin/:issuerSysName/*",
          loader: createOldRouteRedirectLoader(issuerConfigService),
        },
        {
          path: "signup/:issuerSysName/*",
          loader: createOldRouteRedirectLoader(issuerConfigService),
        },
      ],
    },
  ]);

  return withFaroRouterInstrumentation(browserRouter);
};

// TODO: fix custom Router interface vs React Router
// export const createRouter = withFaroRouterInstrumentation(reactBrowserRouter as Router);
