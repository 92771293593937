import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Tooltip,
} from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import {
  getMessageBus,
  getSigninService,
} from "../../../diContainer/getDependencies";
import ConfirmationDialog from "../common/ConfirmationDialog";

interface IProps {
  isIcon?: boolean;
}

const LogOut: FC<IProps> = ({ isIcon }) => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const signInService = getSigninService();
  const messageBus = getMessageBus();

  const [openConfirm, setOpenConfirm] = useState(false);

  const handleLogout = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "LogOutButtonClick",
      source: "ProfilePage",
    });
    messageBus.send(analyticEvent);

    signInService.logOut();
    setOpenConfirm(false);
  };

  return (
    <>
      <Tooltip title={t("logout")}>
        <Button
          variant="text"
          onClick={() => setOpenConfirm(true)}
          sx={{
            padding: "1rem 0",
            fontWeight: "normal",
            textDecoration: "underline",
          }}
        >
          {isIcon ? <LogoutIcon /> : t("logout")}
        </Button>
      </Tooltip>

      <ConfirmationDialog
        open={openConfirm}
        title={t("logoutConfirm")}
        onConfirm={handleLogout}
        onCancel={() => setOpenConfirm(false)}
      />
    </>
  );
};

export default LogOut;
