import { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Button, Typography } from "@mui/material";
import { Trans } from "react-i18next";
import {
  getProfileService,
  getRouterService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import ReadonlyField from "./ReadonlyField";

const ProfileSettings: FC = () => {
  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();
  const routerService = getRouterService();

  const profileData = useObservable(profileService.data$);

  const handleEditProfileInfo = () => {
    routerService.navigateToIssuerPath("/settings/profile/info");
  };

  const handleEditPhone = () => {
    routerService.navigateToIssuerPath("/settings/phone");
  };

  const handleEditEmail = () => {
    routerService.navigateToIssuerPath("/settings/email");
  };

  const handleVerifyPhone = () => {
    if (!profileData?.profile?.phone) return;

    updateProfileService.verifyProfile();
  };

  return (
    <>
      <ReadonlyField
        label="fullName"
        value={`${profileData?.profile?.profile?.[0]?.firstName} ${profileData?.profile?.profile?.[0]?.lastName}`}
        onEdit={handleEditProfileInfo}
      />

      <Box>
        <ReadonlyField
          label="phone"
          value={`${profileData?.profile?.phone}`}
          shouldVerify
          isVerified={profileData?.profile?.phoneVerified}
          onEdit={handleEditPhone}
        />
        {!profileData?.profile?.phoneVerified && (
          <Typography variant="body2" fontSize={{ xs: 16 }} color="warning">
            <Trans
              i18nKey="profile.form.verifyPhone"
              components={{
                button: (
                  <Button
                    sx={{ fontWeight: 700 }}
                    onClick={handleVerifyPhone}
                  ></Button>
                ),
              }}
            />
          </Typography>
        )}
      </Box>

      {/* TODO add change email flow after email implementation */}
      {false && (
        <ReadonlyField
          label="email"
          value={`${profileData?.profile?.email}`}
          shouldVerify
          isVerified={profileData?.profile?.emailVerified}
          onEdit={handleEditEmail}
        />
      )}
    </>
  );
};

export default ProfileSettings;
