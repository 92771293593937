import { StateSubject } from "@roketus/web-toolkit";
import { MetaAttributes } from "@grafana/faro-react";
import { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import {
  IProfileService,
  IProfileServiceData,
} from "../../boundary/IProfileService";
import { toCardInfoEntity } from "../data/toCardInfoEntity";
import { toPromoCodesEntity } from "../data/promoCodes/toPromoCodesEntities";
import { EC_FETCH_PROFILE_INFO } from "../../domain/specs/errorCodes";
import {
  pushErrorToFaro,
  setUserAttributesMetaToFaro,
  setUserMetaToFaro,
} from "../../utils/faroLogs";
import { createDispatchError } from "../helpers";
import { isNoCardError } from "../../utils/response-errors";
import {
  getAuthService,
  getRouterService,
} from "../../diContainer/getDependencies";
import { checkIfProfileActivated } from "../../utils/registration";

const dispatchError = createDispatchError("profileService");

const stateMachine = new StateSubject<IProfileServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  profile: null,
  isProfileActivated: false,
  cardInfo: null,
  promoCodes: [],
});

export const init = function (sdk: ISDK): IProfileService {
  const clearState: IProfileService["clearState"] = () => {
    stateMachine.setState({
      loading: false,
      isLoaded: false,
      failed: false,
      profile: null,
      isProfileActivated: false,
      cardInfo: null,
      promoCodes: [],
    });
  };

  const updateProfileState: IProfileService["updateProfileState"] = (
    profile
  ) => {
    stateMachine.setState({
      profile,
      cardInfo: {
        ...stateMachine.state$.value,
        fullName: `${profile.profile?.[0]?.firstName} ${profile.profile?.[0]?.lastName}`,
      },
      isProfileActivated: checkIfProfileActivated(profile),
    });
  };

  const getProfileInfo: IProfileService["getProfileInfo"] = async () => {
    try {
      const { data: profile } = await sdk.profile.getProfileInfo().result;

      stateMachine.setState({
        profile,
        isProfileActivated: checkIfProfileActivated(profile),
      });

      setUserMetaToFaro(
        "id",
        profile.profile[0]?.id as string & MetaAttributes
      );

      return profile;
    } catch (e) {
      pushErrorToFaro(e, {
        message: "Error: failed to fetch profile info",
      });

      throw e;
    }
  };

  const getFullProfileData: IProfileService["getFullProfileData"] =
    async () => {
      stateMachine.setState({
        loading: true,
        isLoaded: false,
        failed: false,
      });

      try {
        const { data: card } = await sdk.card.getCardInfo().result;

        // Add card number to faro logs
        setUserAttributesMetaToFaro(
          "cardNumber",
          card?.CardNumber as string & MetaAttributes
        );

        const { data: bonusesData } = await sdk.balance.getBonuses().result;
        const { data: passData } = await sdk.pass.getPassByCardNumber(
          card?.CardNumber
        ).result;

        const cardInfo = toCardInfoEntity(card, passData, bonusesData);

        const promoCodes = toPromoCodesEntity(card?.Promocodes || []);

        stateMachine.setState({
          loading: false,
          isLoaded: true,
          cardInfo,
          promoCodes,
        });
      } catch (e) {
        stateMachine.setState({
          loading: false,
          isLoaded: false,
        });

        pushErrorToFaro(e, {
          message: "Error: failed to fetch full profile info",
        });

        const message = "Failed to fetch full profile info";
        dispatchError({
          code: EC_FETCH_PROFILE_INFO,
          error: e,
          message,
        });

        if (isNoCardError(e)) {
          const authService = getAuthService();
          const routerService = getRouterService();

          authService.removeTokens();
          routerService.navigateToIssuerPath("/signup");
        }
      }
    };

  return {
    data$: stateMachine.state$,
    clearState,
    updateProfileState,
    getProfileInfo,
    getFullProfileData,
  };
};
