import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Paper, Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  getRouterService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import { InnerContainer } from "../../../styles/InnerContainer";

interface IProps {
  message: string;
  closeUrl?: string;
}

const SuccessBox: FC<IProps> = ({ message, closeUrl }) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });

  const updateProfileService = getUpdateProfileService();
  const routerService = getRouterService();

  const handleClose = () => {
    updateProfileService.clearState();
    routerService.navigateToIssuerPath(closeUrl ?? "/");
  };

  return (
    <Paper
      sx={(theme) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "none",
        backgroundColor: "#F6F6F6",
        padding: theme.custom.mainPadding,
      })}
    >
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        {message}
      </Typography>
      <CheckCircleIcon color="success" sx={{ fontSize: 57, marginBottom: 4 }} />
      <Button variant="contained" size="small" onClick={handleClose}>
        {t("ok")}
      </Button>
    </Paper>
  );
};

export default SuccessBox;
