import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  Popover,
  Tabs,
  Tab,
  Box,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import HowToUsePromo from "./HowToUsePromo/HowToUsePromo";

const PromoCodeInfoPopover = () => {
  const { t } = useTranslation("translation", { keyPrefix: "promoCodes" });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [tabValue, setTabValue] = useState(0);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTabValue(0);
  };

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  const open = Boolean(anchorEl);
  const id = open ? "promo-code-popover" : undefined;

  return (
    <>
      <Button
        variant="text"
        endIcon={<InfoIcon />}
        sx={{ fontSize: "1.5rem", fontWeight: 500 }}
        onClick={handleClick}
      >
        {t("title")}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        slotProps={{
          paper: {
            style: {
              padding: 2,
              backgroundColor: "#F8F8F8",
              width: 730,
              // transform: "translateX(-146px)"
            },
          },
        }}
      >
        <Tabs value={tabValue} onChange={handleChange} variant="fullWidth">
          <Tab label={t("whatIsPromo")} />
          <Tab label={t("howToUsePromo")} />
        </Tabs>
        <Box role="tabpanel" hidden={tabValue !== 0} padding={2}>
          {tabValue === 0 && (
            <Typography>
              <Trans
                t={t}
                i18nKey={`info`}
                components={{ strong: <strong /> }}
              />
            </Typography>
          )}
        </Box>
        <Box role="tabpanel" hidden={tabValue !== 1} padding={2}>
          {tabValue === 1 && <HowToUsePromo />}
        </Box>
      </Popover>
    </>
  );
};

export default PromoCodeInfoPopover;
