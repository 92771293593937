import { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { UseFormSetError } from "react-hook-form";
import { IOTPFormData } from "../../../boundary/forms/IOTPFormData";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import {
  getMessageBus,
  getSigninService,
} from "../../../diContainer/getDependencies";
import { LayoutHeadless } from "../../components/layouts/LayoutHeadless";
import { ValidateOtp } from "../../components/ValidateOtp";

export const ValidateOtpPage: FC = () => {
  const signInService = getSigninService();
  const messageBus = getMessageBus();

  const data = useObservable(signInService.data$);
  const phoneNumber = data?.phoneNumber ?? "";

  const sendCode = (otp: string, setError: UseFormSetError<IOTPFormData>) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SendOtpButtonClick",
      source: "SignInValidateOtpPage",
    });
    messageBus.send(analyticEvent);

    signInService.logInValidateOTP(otp, setError);
  };

  const handleResend = async () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ResendOtpButtonClick",
      source: "SignInValidateOtpPage",
    });
    messageBus.send(analyticEvent);

    await signInService.logIn(phoneNumber);
  };

  return (
    <LayoutHeadless>
      <ValidateOtp
        onSendOtpClick={sendCode}
        phoneNumber={phoneNumber}
        onResendActivationCode={handleResend}
        isLoading={data?.loading ?? false}
      />
    </LayoutHeadless>
  );
};
