import { StateSubject } from "@roketus/web-toolkit";
import type {
  IImageService,
  IImageServiceData,
  ImageEntity,
} from "../../boundary/IImageService";
import { getSdk } from "../../diContainer/getDependencies";

const stateMachine = new StateSubject<IImageServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  localImages: [],
});

const getImageUrlById: IImageService["getImageUrlById"] = async (id) => {
  stateMachine.setState({ loading: true });

  const { localImages } = stateMachine.state$.value ?? {};
  const localImage = localImages?.find((image) => id === image.id);

  if (localImage)  {
    stateMachine.setState({ loading: false });

    return localImage.base64;
  }

  const sdk = getSdk();

  try {
    const { data } = await sdk.pass.getMediaById(id).result;

    const base64 = data.Base64Image.includes("base64")
      ? data.Base64Image
      : `data:image/jpeg;base64,${data.Base64Image}`;

    const image: ImageEntity = {
      base64,
      id,
    };

    stateMachine.setState({
      localImages: [...localImages, image],
    });

    stateMachine.setState({ loading: false });

    return base64;
  } catch (e) {
    console.error("Image not found", e);

    stateMachine.setState({ loading: false });

    return null;
  }
};

export const imageService: IImageService = {
  data$: stateMachine.state$,
  getImageUrlById,
};
