import { useTranslation } from "react-i18next";
import SettingsLayout from "../../components/layouts/SettingsLayout";

export const EmailVerificationPage = () => {
  const { t } = useTranslation("translation");

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <></>
    </SettingsLayout>
  );
};

export default EmailVerificationPage;
