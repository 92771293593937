import { FC, useEffect, useState } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { CardMedia, Skeleton, useTheme, useMediaQuery } from "@mui/material";
import { getImageService } from "../../../diContainer/getDependencies";

interface IProps {
  imgId?: string | null;
}

const CardImg: FC<IProps> = ({ imgId }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const imageService = getImageService();

  const { loading } = useObservable(imageService.data$) ?? {};

  const [imgUrl, setImgUrl] = useState<string | null>(null);

  const getImgUrl = async (id: string) => {
    const url = await imageService.getImageUrlById(id);
    setImgUrl(url);
  };

  useEffect(() => {
    if (imgId) {
      getImgUrl(imgId);
    }
  }, [imgId]);

  if (loading) {
    return <Skeleton height={isMobile ? 157 : 167} />;
  }

  return (
    <CardMedia
      component="img"
      image={imgUrl ?? ""}
      alt="Main Card"
      width="100%"
    />
  );
};

export default CardImg;
