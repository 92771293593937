import { FC } from "react";
import { Typography } from "@mui/material";

const RegisterCompanyDescription: FC = () => {
  return (
    <>
      <Typography variant="h5" component="h1" align="center" my={3}>
        Вітаємо у Делікат В2В
      </Typography>
      <Typography variant="body1" align="center" mb={3}>
        Пройдіть реєстрацію для отримання доступу для входу у Делікат В2В.
      </Typography>
      <Typography variant="body1" align="center" mb={3}>
        Реєстрація займе не більше 5 хв.
      </Typography>
    </>
  );
};

export default RegisterCompanyDescription;
