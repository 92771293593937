import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { Divider, List, ListItem, styled } from "@mui/material";
import { getIssuerConfigService } from "../../../diContainer/getDependencies";
import { SUPPORT_FORM_URL } from "../../../domain/specs/support";
import LanguageMenu from "./LanguageMenu";
import LogOut from "./LogOut";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  padding: "0.5rem 0",
  color: "inherit",
  fontSize: "1.25rem",
  fontWeight: 500,
  "&:hover": {
    color: theme.palette.primary.main,
  },
  a: {
    color: "inherit",
  },
  svg: {
    fontSize: "1.5rem",
  },
}));

const MenuMobile = () => {
  const { t } = useTranslation("translation", { keyPrefix: "settings" });

  const issuerConfigService = getIssuerConfigService();

  const issuerSysName = issuerConfigService?.getIssuer();
  const termsAndConditionsLink = issuerConfigService?.getTermsLink();

  return (
    <List sx={{ py: 3 }}>
      <StyledListItem secondaryAction={<LanguageMenu isDropdown />}>
        {t("language")}
      </StyledListItem>

      <StyledListItem>
        <NavLink to={`/${issuerSysName}/settings/profile`}>
          {t("profileInfo")}
        </NavLink>
      </StyledListItem>
      <StyledListItem>
        <Link to={termsAndConditionsLink} target="_blank">
          {t("terms")}
        </Link>
      </StyledListItem>
      <StyledListItem>
        <Link to={SUPPORT_FORM_URL} target="_blank">
          {t("help")}
        </Link>
      </StyledListItem>

      <Divider sx={{ pt: 2, mb: 2 }} />

      <StyledListItem secondaryAction={<LogOut isIcon />}>
        {t("logOut")}
      </StyledListItem>
    </List>
  );
};

export default MenuMobile;
