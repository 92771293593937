import { FC, lazy, ReactElement, Suspense } from "react";
import { Container, useTheme, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import Loader from "./Loader";

const ProfileLayout = lazy(() => import("./ProfileLayout"));

interface IProps {
  title?: string;
  children?: ReactElement;
}

const SettingsLayout: FC<IProps> = ({ title, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    return (
      <>
        <Header heading={title} />
        <Container component="main">
          {/* <Outlet /> */}
          {children}
        </Container>
      </>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <ProfileLayout title={title}>
        {children}
      </ProfileLayout>
    </Suspense>
  );
};

export default SettingsLayout;
