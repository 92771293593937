import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Alert, Button } from "@mui/material";
import {
  getProfileService,
  getUpdateProfileService,
} from "../../diContainer/getDependencies";

const ActivationWarning: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile" });

  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();

  const profileData = useObservable(profileService.data$);

  const handleActivation = () => {
    if (!profileData?.profile?.phone) return;

    updateProfileService.verifyProfile();
  };

  return (
    <Alert
      severity="warning"
      sx={{
        alignItems: "center",
        "&> .MuiAlert-icon": { flex: "0 0 22px" },
      }}
      action={
        <Button color="inherit" size="small" onClick={handleActivation}>
          {t("activate")}
        </Button>
      }
    >
      {t("notActivatedWarning")}
    </Alert>
  );
};

export default ActivationWarning;
