import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormSetError } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import {
  getProfileService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import { IEmailForm } from "../../../boundary/forms/IProfileForm";
import { getEmailFieldsConfig } from "./fields";
import FormBuilder from "../form/FormBuilder";
import SuccessBox from "./SuccessBox";

const ProfileEmailForm: FC = () => {
  const { t } = useTranslation("translation");

  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();

  const profileData = useObservable(profileService.data$);
  const updateProfileData = useObservable(updateProfileService.data$);

  const isLoading = profileData?.loading || updateProfileData?.loading;

  const [isSuccess, setIsSuccess] = useState(false);

  const setSuccess = () => setIsSuccess(true);

  const handleEmailSave = (
    data: IEmailForm,
    setError: UseFormSetError<IEmailForm>
  ) => {
    updateProfileService.updateEmail(data, setSuccess, setError);
  };

  if (isSuccess) {
    return (
      <SuccessBox
        message={t("profile.form.successEmailUpdated") ?? ""}
        closeUrl="/settings/profile"
      />
    );
  }

  const emailFieldsConfig = getEmailFieldsConfig(t);

  return (
    <FormBuilder<IEmailForm>
      isLoading={isLoading}
      initialValues={{
        email: profileData?.profile?.email,
      }}
      fieldsConfig={emailFieldsConfig}
      submitLabel={t("profile.form.update")}
      onSave={handleEmailSave}
    />
  );
};

export default ProfileEmailForm;
