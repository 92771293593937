import { FC, Fragment, useMemo, useState } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Button, Divider, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LANGUAGE_OPTIONS } from "../../../adapters/i18n/i18n";
import { getLocalizationService } from "../../../diContainer/getDependencies";

interface IProps {
  isDropdown?: boolean;
}

const LanguageMenu: FC<IProps> = ({ isDropdown }) => {
  const localizationService = getLocalizationService();

  const localizationData = useObservable(localizationService.data$);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [options, currentOption] = useMemo(
    () => [
      LANGUAGE_OPTIONS.filter(
        (opt) => opt.value !== localizationData?.language
      ),
      LANGUAGE_OPTIONS.find((opt) => opt.value === localizationData?.language),
    ],
    [localizationData?.language]
  );

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleChange = (lang: string) => {
    localizationService.setLocale(lang);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isDropdown) {
    return (
      <>
        <Button
          id="language-button"
          aria-controls={open ? "language-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          variant="text"
          sx={{
            padding: "1rem 0",
            minWidth: "fit-content",
            fontWeight: "normal",
          }}
          onClick={handleOpen}
        >
          {currentOption?.shortLabel} <ArrowDropDownIcon />
        </Button>
        <Menu
          id="language-menu"
          aria-labelledby="language-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
        >
          {options.map((opt) => (
            <MenuItem
              key={opt.value}
              sx={{ fontSize: { xs: "1.5rem", md: "1rem" }}}
              onClick={() => handleChange(opt.value)}
            >
              {opt.shortLabel}
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 2,
        mb: 2,
      }}
    >
      {options.map((opt, idx) => (
        <Fragment key={opt.value}>
          {idx > 0 && (
            <Divider
              orientation="vertical"
              variant="inset"
              sx={{ m: 0 }}
              flexItem
            />
          )}
          <Button
            color="primary"
            variant="text"
            disableRipple
            onClick={() => handleChange(opt.value)}
            sx={{ textTransform: "none", p: 1, lineHeight: "normal" }}
          >
            {opt.label}
          </Button>
        </Fragment>
      ))}
    </Box>
  );
};

export default LanguageMenu;
