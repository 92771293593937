import { FC, ReactElement } from "react";
import { Box, Paper, Typography } from "@mui/material";

interface IProps {
  title?: string;
  children: ReactElement;
}

const SectionContainer: FC<IProps> = ({ title, children }) => {
  return (
    <Box>
      <Typography variant="h4" px={{ xs: 3, md: 0 }} mb={{ xs: 2, md: 1 }}>
        {title}
      </Typography>
      <Paper
        sx={(theme) => ({
          borderRadius: { md: "12px" },
          boxShadow: "0px -1px 8px 0px rgba(0,0,0,0.15)",
          padding: theme.custom.mainPadding,
        })}
      >
        {children}
      </Paper>
    </Box>
  );
};

export default SectionContainer;
