import { Fragment, useEffect, useState } from "react";
import {
  useForm,
  FieldValues,
  DefaultValues,
  Path,
  UseFormSetError,
} from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import type { IFormFieldConfig } from "../../../boundary/forms/IFormFieldConfig";
import { PhoneInput } from "./PhoneInput";
import { Input } from "./Input";
import { Select } from "./Select";

interface IProps<T extends FieldValues> {
  width?: string | number;
  isLoading?: boolean;
  initialValues: DefaultValues<T>;
  fieldsConfig: IFormFieldConfig<T>[];
  submitLabel: string;
  onSave: (data: T, setError: UseFormSetError<T>) => void;
}

function FormBuilder<T extends FieldValues>({
  width,
  isLoading,
  initialValues,
  fieldsConfig,
  submitLabel,
  onSave,
}: IProps<T>) {
  const { control, handleSubmit, formState, setError, reset } = useForm<T>({
    mode: "onBlur",
    defaultValues: initialValues,
    resetOptions: { keepValues: true, keepDirtyValues: true },
  });

  const { isDirty, isValid } = formState;

  const [isFirstRender, setFirstRender] = useState(false);

  useEffect(() => {
    if (Object.keys(initialValues).length && !isFirstRender) {
      reset(initialValues);
      setFirstRender(true);
    }
  }, [initialValues, reset, isFirstRender]);

  const handleSave = (data: T) => {
    onSave(data, setError);
  };

  return (
    <Box
      component="form"
      sx={{
        mb: 2,
        display: "flex",
        flexDirection: "column",
        width: width ?? "100%",
      }}
    >
      {fieldsConfig.map(
        ({ name, label, hint, type, rules, options, textFieldProps }) => {
          return (
            <Fragment key={name as string}>
              {hint && (
                <Typography variant="body2" mb={1.5}>
                  {hint}
                </Typography>
              )}

              {type === "phone" && (
                <PhoneInput<T>
                  name={name as Path<T>}
                  control={control}
                  rules={rules}
                  label={label}
                  fullWidth
                />
              )}

              {type === "select" && (
                <Select<T>
                  name={name as Path<T>}
                  control={control}
                  rules={rules}
                  label={label}
                  options={options}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}

              {(type === "text" || type === "number" || type === "email") && (
                <Input<T>
                  {...(textFieldProps || {})}
                  name={name as Path<T>}
                  control={control}
                  type={type}
                  rules={rules}
                  label={label}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              )}
            </Fragment>
          );
        }
      )}

      <LoadingButton
        type="submit"
        disabled={!isValid || !isDirty}
        loading={isLoading}
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 3, display: "block", marginLeft: "auto" }}
        onClick={handleSubmit(handleSave)}
      >
        {submitLabel}
      </LoadingButton>
    </Box>
  );
}

export default FormBuilder;
