import { Trans, useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import styles from "./HowToUsePromo.module.scss";

const HowToUsePromo = () => {
  const { t } = useTranslation("translation", { keyPrefix: "promoCodes" });

  return (
    <>
      <ol className={styles.list}>
        <li>
          <Trans
            t={t}
            i18nKey="steps.checkCodes.title"
            components={{ bold: <strong /> }}
          />
          <ul className={styles.sublist}>
            <li>
              <Trans t={t} i18nKey="steps.checkCodes.content" />
            </li>
          </ul>
        </li>
        <li>
          <Trans
            t={t}
            i18nKey="steps.atCheckout.title"
            components={{ bold: <strong /> }}
          />
          <ul className={styles.sublist}>
            <li>
              <Trans t={t} i18nKey="steps.atCheckout.content.0" />
            </li>
            <li>
              <Trans t={t} i18nKey="steps.atCheckout.content.1" />
            </li>
          </ul>
        </li>

        <li>
          <Trans
            t={t}
            i18nKey="steps.discount.title"
            components={{ bold: <strong /> }}
          />
          <ul className={styles.sublist}>
            <li>
              <Trans t={t} i18nKey="steps.discount.content" />
            </li>
          </ul>
        </li>
      </ol>
    </>
  );
};

export default HowToUsePromo;
