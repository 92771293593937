import { useTranslation } from "react-i18next";
import { useTheme, useMediaQuery } from "@mui/material";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import ProfileSettings from "../../components/ProfileSettings/ProfileSettings";
import ProfileSettingsCard from "../../components/ProfileSettings/ProfileSettingsCard";

export const ProfileSettingPage = () => {
  const { t } = useTranslation("translation");

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <ProfileSettingsCard
        title={t("profile.form.profileInfo") ?? ""}
        backUrl={isMobile ? "/settings" : null}
      >
        <ProfileSettings />
      </ProfileSettingsCard>
    </SettingsLayout>
  );
};

export default ProfileSettingPage;
