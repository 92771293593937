import { useTranslation } from "react-i18next";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import ProfileInfoForm from "../../components/ProfileSettings/ProfileInfoForm";

export const ProfileInfoSettingPage = () => {
  const { t } = useTranslation("translation");

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <ProfileInfoForm />
    </SettingsLayout>
  );
};

export default ProfileInfoSettingPage;
