import { FC, ReactElement, useEffect } from "react";
import { isNil } from "lodash";
import { Grid2 as Grid } from "@mui/material";
import { useObservable } from "@roketus/web-toolkit";
import {
  getIssuerConfigService,
  getProfileService,
} from "../../diContainer/getDependencies";
import ActivationWarning from "./ActivationWarning";
import ReferralProgramBox from "./ReferralProgramBox";
import PromoCodeBox from "./PromoCodesBox/PromoCodeBox";
import MainInfoBox from "./MainInfoBox";

interface IProps {
  children?: ReactElement;
}

const Profile: FC<IProps> = ({ children }) => {
  const profileService = getProfileService();
  const issuerConfigService = getIssuerConfigService();

  const profileData = useObservable(profileService.data$);
  const issuerData = useObservable(issuerConfigService.data$);

  useEffect(() => {
    if (profileData && !profileData.loading && !profileData.isLoaded) {
      profileService.getFullProfileData();
    }
  }, [profileData, profileService]);

  return (
    <>
      {profileData &&
        !profileData?.loading &&
        !profileData?.isProfileActivated && <ActivationWarning />}

      <Grid container spacing={2} mb={2} flexDirection={{ md: "row-reverse" }}>
        <Grid size={{ xs: 12, md: 6 }} mb={{ xs: 1, md: 0 }}>
          {/* <Outlet /> */}
          {children}
        </Grid>

        <Grid size={{ xs: 12, md: 6 }}>
          <Grid container spacing={3}>
            <Grid size={{ xs: 12 }}>
              <MainInfoBox />
            </Grid>
            {(isNil(issuerData?.config?.isRefProgramSet) ||
              issuerData?.config?.isRefProgramSet) && (
                <Grid size={{ xs: 12 }}>
                  <ReferralProgramBox />
                </Grid>
              )}
          </Grid>
        </Grid>
      </Grid>

      <PromoCodeBox />
    </>
  );
};

export default Profile;
