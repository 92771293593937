import {
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_NO_CARD,
} from "../../domain/specs/errorCodes";

const en = {
  translation: {
    forms: {
      default: {
        required: "* Required field",
      },
      isRequired: "{{label}} is required",
      minLength: "{{label}} must be at least 2 characters",
      maxLength: "{{label}} must be less than 50 characters",
      invalid: "{{label}} is not valid",
      "incorrect first name, can contain only letters and numbers": "First/Last name cannot contain numbers",
      "incorrect last name, can contain only letters and numbers": "First/Last name cannot contain numbers",
      nameInvalid: "First/Last name cannot contain numbers",
    },
    common: {
      ok: "Ok",
      yes: "Yes",
      no: "No",
      copy: "Copy",
      copiedMessage: "Link copied!",
      phoneInput: {
        placeholder: "Enter phone number",
      },
      errors: {
        "user not found": "User not found",
        "wrong phone code": "Wrong phone code",
        isRequired: "{{label}} is required",
        unknownTitle: "Error",
        unknownText: "Oops, something went wrong",
        minLength: "Input value is too short",
        phone: {
          required: "Invalid phone number",
          "phone belong another user": "Phone number already registered",
          unknownText: "Oops, something went wrong, please try again later",
          "user not found": "User not found",
          "incorrect phone": "Incorrect phone number",
          phoneIncorrect: "Phone Number is too short",
        },
        otp: {
          required: `otp is required`,
          pattern: "only numbers are allowed",
          minLength: "Min length 6 symbols",
          maxLength: "Max length 6 symbols",
          "wrong phone code": "Wrong phone code",
          unknownText: "Oops, something went wrong, please try again later",
        },
      },
      date: {
        month: {
          January: "January",
          February: "February",
          March: "March",
          April: "April",
          May: "May",
          June: "June",
          July: "July",
          August: "August",
          September: "September",
          October: "October",
          November: "November",
          December: "December",
        },
      },
      showAll: "Show all",
      hide: "Hide",
    },
    header: {
      settings: "Settings",
      terms: "Terms",
      help: "Help",
      profileInfo: "Profile Information",
    },
    inAppBrowserWarning: {
      warningTitle: "Open in Browser",
      copyMessage:
        "Please copy this link and open it in the browser to fully enjoy the application.",
      orMessage:
        "Or use long press on the link in the message <span>-> 'Open in...'</span>",
    },
    signIn: {
      index: {
        header: "Sign In",
        text: "Choose Your Country Code and enter your phone number",
        buttonLabel: "Sign Up",
        confirmationLabel: "Send Confirmation Code",
        company: "Company",
      },
      validateOtp: {
        header: "Please enter your verification code",
        text: "Enter the 6-digit code we have sent to <span>xxx xxxx {{phoneNumberSlice}}.</span>",
        changePhoneNumber: "Edit phone number",
        noCode: "Did not receive verification code?",
        resendCode: "Resend code",
        waitResend: "You can resend the code after:",
        buttonLabel: "Verify code",
        notReceivingOtp: "Not receiving OTP",
      },
    },
    signUp: {
      setPhone: {
        header: "Sign Up",
        text: "We will send you a 6-digit verification code",
        buttonLabel: "Send Code {{countdownTitle}}",
        termsAndConditionsLabel:
          "I agree to the <lt>TERMS AND CONDITIONS</lt> AND <lc>PRIVACY POLICY</lc>",
        hasAccount: "Already have an account?",
        company: "Company",
      },
      phoneActivation: {
        title: "You’re in!",
        header:
          "Welcome to {{issuerTitle}} loyalty program and all its benefits.<br />Enjoy a wide range of earnings, offers and more.",
        bonuses: "YOU WILL RECEIVE YOUR BONUSES SHORTLY!",
        moreBonuses: "Earn more bonuses",
        bonusesProgram: "Bonuses Program",
        complete: "Complete your activation to add more bonuses.",
        buttonLabel: "Continue",
      },
      setProfile: {
        firstNameLabel: "First Name",
        lastNameLabel: "Last Name",
        continueButton: "Continue",
        skipButton: "Skip",
        header: "Please enter your name",
        cardPreview: "Card Preview",
      },
      setBirthday: {
        header: "Please enter your birthday",
        continueButton: "Continue",
        dayLabel: "Day of the month",
        monthLabel: "Month",
        yearLabel: "Year",
      },
      step: {
        Basic: "",
        Profile: "Set Profile",
        SetPhone: "",
        PhoneActivation: "",
        PhoneActivationSuccess: "",
        SetEmail: "Verify Your Email",
        EmailActivation: "",
        SetBirthday: "Set Birthday",
        Completed: "Profile",
      },
    },
    profile: {
      title: "Your Account",
      mainInfo: "Main Information",
      company: "Company: {{name}}",
      organizationName: "Organization Name:",
      description: "Description:",
      balance: "Balance",
      userName: "User Name",
      level: "Level",
      purchaseBonuse: "Purchase Bonuse",
      companyName: "Company:",
      mainText:
        "This is your {{issuerTitle}} loyalty program account where you can check your balance, invite friends and manage your personal information.",
      dateLabel: "Valid through: ",
      downloadCardForIos: "Download card for iOS",
      downloadCardForAndroid: "Download card for Android",
      balanceLabel: "Balance",
      discountLabel: "Purchase Bonus",
      cardNumberLabel: "Card Number",
      downloadCardLabel: "Download your Digital Card",
      logout: "Log Out",
      logoutConfirm: "Are you sure you want to exit?",
      promoCodes: "Promo Codes",
      notActivatedWarning: "To use bonuses, please confirm your phone number.",
      activate: "Activate",
      form: {
        profileInfo: "Profile Information",
        updateInfoTitle: "Update your Full Name information",
        updatePhoneTitle: "Update your Phone Number",
        updateEmailTitle: "Update your Email",
        save: "Save",
        update: "Update",
        fullName: "Full Name",
        firstNameLabel: "First Name",
        lastNameLabel: "Last Name",
        successInfoUpdated: "Your Full Name has been updated",
        phone: "Phone Number",
        phoneHint: "Please enter a new phone number",
        phoneRequired: "Phone Number is required",
        phoneInvalid: "Invalid phone number format",
        successPhoneUpdated: "Your Phone Number has been updated",
        verifyPhone: "Phone number is not verified, <button>FOLLOW</button> to fix it",
        email: "Email",
        emailHint: "Please enter a new email address",
        emailRequired: "Email is required",
        emailInvalid: "Invalid email format",
        successEmailUpdated: "Your Email adress has been updated",
      }
    },
    settings: {
      settings: "Settings",
      language: "Language",
      profileInfo: "Profile Information",
      terms: "Terms",
      help: "Help",
      logOut: "Log out",
      back: "Back",
    },
    changeProfile: {
      title: "Update Personal Info",
      buttonLabel: "Update",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
    },
    changePhoneNumber: {
      title: "Update your mobile phone number",
      buttonLabel: "Update",
      updateButtonLabel: "Update {{time}}",
    },
    changeEmail: {
      title: "Update your email",
      buttonLabel: "Update",
    },
    emailVerification: {
      send: "Send Confirmation",
      skip: "Skip",
      title: "Verify Email Address!",
      message: "Please enter your valid email address.",
      email: "Email",
    },
    referralProgram: {
      referralProgram: "Referral Program",
      title: "Get More bonuses!",
      subtitle: "Invite a friend and earn bonuses.",
      description:
        "When a new customer creates an account and installs a loyalty card, you and your friend will receive bonuses.",
    },
    promoCodes: {
      title: "Promo Codes",
      promoCode: "Promo Code",
      numberOfUses: "Number of Uses",
      endDate: "End Date",
      description: "Description",
      whatIsPromo: "What is a Promo Code?",
      info: "A <strong>promo code</strong> is a special code that gives you a discount on a product or service when you make a purchase. Promo codes are linked to your loyalty program card, allowing you to save money on selected items or services.",
      howToUsePromo: "How to Use a Promo Code?",
      steps: {
        checkCodes: {
          title: "<bold>Check Promo Codes:</bold>",
          content:
            "Open your loyalty card in your mobile wallet or web version to see available promo codes.",
        },
        atCheckout: {
          title: "<bold>At the Checkout:</bold>",
          content: [
            "Show your card to the cashier or provide your phone number.",
            "The cashier will scan your card, and the promo code will be automatically applied to your purchase.",
          ],
        },
        discount: {
          title: "<bold>Discount:</bold>",
          content:
            "The cashier will inform you of the discount, and you can complete your purchase with savings.",
        },
      },
    },
    action: {
      thanksForScanning: "Thanks for scanning the QR code!",
      thanksForChoosing:
        "We sincerely thank you for choosing to take advantage of our special offer!",
      valuableCustomer:
        "You are our most valuable customers, and your choice makes us even better",
      reward: "Reward",
      bonus: {
        reward:
          "You have a chance to get 100 bonuses that you can spend in our store",
        leavePhone: "Please leave your phone number to receive bonuses",
      },
      coupon: {
        reward:
          "You have a chance to get a coupon that you can use in our store",
        leavePhone: "Please leave your phone number and receive it",
      },
      promo: {
        reward:
          "You have a chance to get a promo code that you can use in our store",
        leavePhone: "Please leave your phone number and receive it",
      },
      notification: {
        reward: "You have a chance to get a unique offer from our store",
        leavePhone: "Please leave your phone number and receive it",
      },
      survey: {
        reward:
          "You have a chance to receive 100 bonuses that you can spend in our store after completing the survey",
        leavePhone: "Please leave your phone number to receive bonuses",
      },
      surveyForm: {
        question: "Do you like our store?",
        yes: "Yes",
        no: "No",
        next: "Next",
      },
      dispatchResult: {
        successHeader: "Congratulations!",
        failHeader: "Something went wrong",
        sorryHeader: "Sorry",
        warning: "WARNING!",
        successBonusText:
          "You will be awarded bonus points<br />*bonus points will be activated according to LP rules",
        successCouponText: "You have received an offer",
        successPromoText: "You will receive a promo code",
        successNotificationText: "You will receive an offer",
        failBonusText: "Bonuses have not been awarded",
        failCouponText: "Failed to send coupon",
        failNotificationText: "Failed to send message",
        failPromoText: "Failed to send promo code",
        unknownErrorText: "The offer is already activated",
        promoCodeNotFound: "The Promo code could not be found",
        alreadyUsedBonuses: "Bonuses have already been used",
        alreadyAddedPromoCode: "Promo code has already been added",
        alreadyUsedPromoCode: "The gift promo code has already been used",
        promoCodeExpired: "Promotional offer is no longer available",
        promoCodeNotAvailable: "Promotional offer is not available",
        toMainPage: "Go to main page",
        close: "Close",
        pleaseWait: "Please, wait",
      },
      demoatomic: {
        bonusTitle: "<span>Thank</span> you for scanning the QR code!",
        surveyTitle:
          "Please answer <span>one question</span>. Did you enjoy being in our store?",
        qrTitle: "<span>Only today!</span> Exclusive offer",
        couponTitle:
          "<span>Only today!</span> Join now and get exclusive discounts and bonuses!",
        successTitle: "<span>Thanks</span> for your time and cooperation",
        errorTitle: "<span>Oops!</span> Something went wrong",
        sorryTitle: "<span>Sorry!</span><br/>See you soon!",
        bonusText:
          "We sincerely thank you for choosing to take advantage of <b>our special offer</b>! You are our most valuable customer and your choice makes us even better. You have a chance to get the benefits of <b>our loyalty program</b>",
        surveyQuestion: "Did you enjoy being in our store?",
        surveyYes: "I really liked it",
        surveyNo: "Could be better",
        phoneLabel: "Please leave your phone number to use this opportunity",
        homeButton: "Home",
        close: "Cancel",
        confirm: "Confirm",
      },
    },
    landing: {
      title: "Product information",
      text: "Our products are the best among all products",
    },
    welcomePage: {
      ok: "OK",
      cancel: "Cancel",
    },
    infoPage: {
      message: "Sorry.<br />See you soon!",
      notFound: "Sorry.<br />Page not found",
    },
    footer: {
      help: "Help",
    },
    errorPage: {
      [EC_FAILED_FETCH_CONFIG_DATA]: "Failed to fetch config",
      [EC_INVALID_ISSUER_SYS_NAME]:
        "Something wrong with the link, please scan again",
      [EC_NO_CARD]: "Loyalty card not found",
      unknownTitle: "Oops!",
      unknownText:
        "The Loyalty Program you are trying to access has not started yet or the URL is incorrect. Coming soon, stay tuned!",
      actionText: "Go to Home Page",
    },
  },
};

export type Ii18nScheme = typeof en;

export default en;
