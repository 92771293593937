import { IPromoCodeDTO } from "@roketus/loyalty-end-user-js-sdk";
import { IPromoCodeEntity } from "../../../domain/entities/PromocodeEntity";

export const toPromoCodesEntity = (
  promoCodesDTO: IPromoCodeDTO[]
): IPromoCodeEntity[] =>
  promoCodesDTO.map((promoCodeDto) => ({
    id: promoCodeDto.ID,
    name: promoCodeDto.Name,
    description: promoCodeDto.Description,
    countOfUses: promoCodeDto.CountOfUse,
    validTill: promoCodeDto.ValidTill,
  }));
