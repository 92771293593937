import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormSetError } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import {
  getProfileService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import { IProfileInfoForm } from "../../../boundary/forms/IProfileForm";
import { getProfileInfoFieldsConfig } from "./fields";
import FormBuilder from "../form/FormBuilder";
import SuccessBox from "./SuccessBox";
import ProfileSettingsCard from "./ProfileSettingsCard";

const ProfileInfoForm: FC = () => {
  const { t } = useTranslation("translation");

  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();

  const profileData = useObservable(profileService.data$);
  const updateProfileData = useObservable(updateProfileService.data$);

  const isLoading = profileData?.loading || updateProfileData?.loading;

  const [isSuccess, setIsSuccess] = useState(false);

  const setSuccess = () => setIsSuccess(true);

  const handleProfileInfoSave = async (
    data: IProfileInfoForm,
    setError: UseFormSetError<IProfileInfoForm>
  ) => {
    await updateProfileService.updateProfileInfo(data, setSuccess, setError);
  };

  if (isSuccess) {
    return (
      <ProfileSettingsCard canClose={false}>
        <SuccessBox
          message={t("profile.form.successInfoUpdated") ?? ""}
          closeUrl="/settings/profile"
        />
      </ProfileSettingsCard>
    );
  }

  const profileInfoFieldsConfig = getProfileInfoFieldsConfig(t);

  return (
    <ProfileSettingsCard
      title={t("profile.form.updateInfoTitle") ?? ""}
      backUrl="/settings/profile"
    >
      <FormBuilder<IProfileInfoForm>
        isLoading={isLoading}
        initialValues={
          profileData
            ? {
                firstName: profileData?.profile?.profile?.[0]?.firstName,
                lastName: profileData?.profile?.profile?.[0]?.lastName,
              }
            : {}
        }
        fieldsConfig={profileInfoFieldsConfig}
        submitLabel={t("profile.form.update")}
        onSave={handleProfileInfoSave}
      />
    </ProfileSettingsCard>
  );
};

export default ProfileInfoForm;
