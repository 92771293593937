import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@roketus/web-toolkit";
import { Typography } from "@mui/material";
import {
  getMessageBus,
  getSignupService,
} from "../../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import type { IProfileInfoForm } from "../../../boundary/forms/IProfileForm";
import { getProfileInfoFieldsConfig } from "../../components/ProfileSettings/fields";
import { LayoutHeadless } from "../../components/layouts/LayoutHeadless";
import FormBuilder from "../../components/form/FormBuilder";

type TFormData = { firstName: string; lastName: string };

const defaultValues = {
  firstName: "",
  lastName: "",
};

export const SignupProfilePage: FC = () => {
  const { t } = useTranslation("translation");

  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const data = useObservable(signupService.data$);

  const onSubmit = ({ firstName, lastName }: TFormData) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SetProfileButtonClick",
      source: "SignUpProfilePage",
    });
    messageBus.send(analyticEvent);

    signupService.setProfile(firstName, lastName);
  };

  const profileInfoFieldsConfig = getProfileInfoFieldsConfig(t);

  return (
    <LayoutHeadless>
      <Typography component="h1" variant="h4" mb={2}>
        {t("signUp.setProfile.header")}
      </Typography>
      <FormBuilder<IProfileInfoForm>
        isLoading={data?.loading}
        initialValues={defaultValues}
        fieldsConfig={profileInfoFieldsConfig}
        submitLabel={t("signUp.setProfile.continueButton")}
        onSave={onSubmit}
      />
    </LayoutHeadless>
  );
};
