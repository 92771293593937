import { IProfileInfoResponseAttributes } from "@roketus/loyalty-end-user-js-sdk";
import { isEmpty, isString } from "lodash";
import { getTemplateIdByCampaign } from "./action-utiles";

export const isInvalidIssuerSysName = (
  issuerSysName?: string | null
): boolean => {
  return (
    !issuerSysName ||
    !isString(issuerSysName) ||
    isEmpty(issuerSysName) ||
    issuerSysName === "null" ||
    issuerSysName === "undefined"
  );
};

export const isRegistrationCompleted = (response: any): boolean => {
  return response?.activation?.[0]?.completed;
};

export const isOtpStep = (response: any): boolean => {
  return ["phoneActivation", "terms"].includes(
    response?.activation?.[0]?.nextStep
  );
};

export const getNextRegStep = (response: any): string => {
  return response?.activation?.[0]?.nextStep || "";
};

export const getNextRegStepPath = (
  nextStep: string = "",
  isRedirectedFromError?: boolean
): string => {
  switch (nextStep) {
    case "completed":
      return "/";
    case "profile":
      return "/signup/profile";
    case "phoneActivation":
    case "terms":
      return isRedirectedFromError ? "/signup" : "/signup/validate-otp";
    default:
      return "/signup";
  }
};

export const checkIfProfileActivated = (
  profile?: IProfileInfoResponseAttributes
): boolean => {
  return (
    !!profile &&
    profile.activation[0].completed &&
    (profile.emailVerified || profile.phoneVerified)
  );
};

export const getRegStartParamsFromQuery = (
  query: URLSearchParams,
  actionType?: string,
): {
  refCode?: string;
  companyId?: string;
  shop?: string;
  campaign?: string;
  templateId?: number;
} => {
  const refCode = query.get("refCode") ?? undefined;
  const companyId = query.get("company") ?? undefined;
  const shop = query.get("shop") ?? undefined; // TODO add to new reg methods
  const campaign = query.get("campaign") ?? undefined; // TODO add to new reg methods
  const template = query.get("template") ?? undefined; // TODO add to new reg methods
  const templateId = template ? Number(template) : getTemplateIdByCampaign(campaign ?? actionType);

  return {
    refCode,
    companyId,
    shop,
    campaign,
    templateId,
  };
};
