import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { UseFormSetError } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import {
  getMessageBus,
  getProfileService,
  getRouterService,
  getUpdateProfileService,
} from "../../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";
import { IOTPFormData } from "../../../boundary/forms/IOTPFormData";
import { ValidateOtp } from "../ValidateOtp";
import ProfileSettingsCard from "./ProfileSettingsCard";
import SuccessBox from "./SuccessBox";

const ProfileOtpForm: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "profile.form" });

  const profileService = getProfileService();
  const updateProfileService = getUpdateProfileService();
  const routerService = getRouterService();
  const messageBus = getMessageBus();

  const profileData = useObservable(profileService.data$);
  const updateProfileData = useObservable(updateProfileService.data$);

  const [isSuccess, setIsSuccess] = useState(false);

  const setSuccess = () => setIsSuccess(true);

  const handleActivateProfile = async (
    otp: string,
    setError: UseFormSetError<IOTPFormData>
  ) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SendActivationCodeButtonClick",
      source: "PhoneActivationPage",
    });
    messageBus.send(analyticEvent);

    await updateProfileService.activateProfileByOTP(otp, setSuccess, setError);
  };

  const handleResendCode = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ResendActivationOtpButtonClick",
      source: "PhoneActivationPage",
    });
    messageBus.send(analyticEvent);

    updateProfileService.resendActivationCode();
  };

  const handleEditPhoneNumber = () => {
    routerService.navigateToIssuerPath("/settings/phone");
  };

  const phone =
    updateProfileData?.phoneNumber || profileData?.profile?.phone || "";

  if (isSuccess) {
    return (
      <ProfileSettingsCard canClose={false}>
        <SuccessBox
          message={t("successPhoneUpdated") ?? ""}
          closeUrl="/settings/profile"
        />
      </ProfileSettingsCard>
    );
  }

  return (
    <ProfileSettingsCard backUrl="/settings/phone">
      <ValidateOtp
        isLoading={updateProfileData?.loading ?? false}
        phoneNumber={phone}
        onSendOtpClick={handleActivateProfile}
        onResendActivationCode={handleResendCode}
        onEditPhoneNumber={handleEditPhoneNumber}
      />
    </ProfileSettingsCard>
  );
};

export default ProfileOtpForm;
