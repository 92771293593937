import { useMemo } from "react";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  RegisterOptions,
} from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { MuiTelInput, MuiTelInputCountry } from "mui-tel-input";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
} from "../../../adapters/services/localizationService";
import { getLocalizationService } from "../../../diContainer/getDependencies";

type IProps<R extends FieldValues> = {
  name: Path<R>;
  control?: Control<R>;
  label?: string;
  value?: string;
  readOnly?: boolean;
  rules?: Omit<
    RegisterOptions<R, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
} & TextFieldProps;

const MIN_PHONE_LENGTH = 9;

export const PhoneInput = <R extends FieldValues>({
  name,
  control,
  label,
  readOnly,
  rules,
  ...props
}: IProps<R> & TextFieldProps) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common.phoneInput",
  });
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "common.errors.phone",
  });

  const localizationService = getLocalizationService();

  const localizationData = useObservable(localizationService.data$);

  const [isoCountryCode, isoLangCode]: [MuiTelInputCountry, string] = useMemo(
    () => [
      (localizationData?.country || DEFAULT_COUNTRY_CODE) as MuiTelInputCountry,
      localizationData?.language || DEFAULT_LANGUAGE_CODE,
    ],
    [localizationData]
  );

  if (readOnly) {
    return (
      <MuiTelInput
        {...props}
        label={label}
        preferredCountries={[isoCountryCode]}
        defaultCountry={isoCountryCode}
        langOfCountryName={isoLangCode}
        onChange={() => {}}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: tError("required") ?? "",
        minLength: { value: MIN_PHONE_LENGTH, message: tError("phoneIncorrect") },
        ...(rules || {}),
      }}
      render={({ field, fieldState }) => {
        const { value, onChange } = field;
        const errorText = fieldState.error?.message
          ? fieldState.error?.message ?? tError("required")
          : null;

        return (
          <MuiTelInput
            {...props}
            {...field}
            fullWidth
            value={value}
            onChange={onChange}
            preferredCountries={[isoCountryCode]}
            defaultCountry={isoCountryCode}
            langOfCountryName={isoLangCode}
            helperText={errorText}
            error={fieldState.invalid} // labels={ua}
            label={label ?? t("placeholder")}
            sx={{ mt: 2, mb: 1 }}
            data-testid="phone-input"
          />
        );
      }}
    />
  );
};
