import { FC } from "react";
import { Box, Button } from "@mui/material";
import LayoutDelicate from "../../layouts/LayoutDelicate";
import RegisterCompanyDescription from "../delicate/RegisterCompanyDescription";

interface IProps {
  onSubmit: () => void;
  onCancel: () => void;
}

const DelicateWelcomePage: FC<IProps> = ({ onSubmit, onCancel }) => {
  return (
    <LayoutDelicate>
      <Box>
        <RegisterCompanyDescription />
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-end",
            mt: 5,
          }}
        >
          {/* <Button variant="outlined" onClick={onCancel}>
            {"Відміна"}
          </Button> */}
          <Button
            variant="contained"
            onClick={onSubmit}
          >
            {"Далі"}
          </Button>
        </Box>
      </Box>
    </LayoutDelicate>
  );
};

export default DelicateWelcomePage;
