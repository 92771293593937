import { RouteObject } from "react-router-dom";
import type { IActionService } from "../../boundary/IActionService";
import { createActionLoader } from "./loaders/actionLoader";
import { WelcomePage } from "../../view/pages/action/WelcomePage";

export const ACTION_DISPATCH_SEARCH_PARAM = "dispatch";

export enum ActionsPaths {
  Bonus = "bonus",
  Coupon = "coupon",
  Notification = "notification",
  Survey = "survey",
  Promo = "promo",
  VodohraiPromo = "vodohrai", // TODO: remove; HARDCODE for OnDe vodohrai promo
  SensePromo = "sense", // TODO: remove; HARDCODE for OnDe sense promo
}

export enum AnonymousActionsPaths {
  AnonymousCoupon = "free-coupon",
  // FreeSurvey = "free-survey",
  FreeSurvey = "register-company",
}

export const ANONYMOUS_ACTIONS_PATHS = [
  AnonymousActionsPaths.AnonymousCoupon,
  AnonymousActionsPaths.FreeSurvey,
];

export const getActionsRoutes = (
  actionService: IActionService
): RouteObject[] => {
  return [
    ActionsPaths.Bonus,
    ActionsPaths.Coupon,
    ActionsPaths.Notification,
    ActionsPaths.Survey,
    ActionsPaths.Promo,
    ActionsPaths.VodohraiPromo,
    ActionsPaths.SensePromo,
  ].map((actionPath) => ({
    path: actionPath,
    element: <WelcomePage />,
    loader: createActionLoader(actionService),
  }));
};

export const getAnonymousActionsRoutes = (
  actionService: IActionService
): RouteObject[] => {
  return [
    AnonymousActionsPaths.AnonymousCoupon,
    AnonymousActionsPaths.FreeSurvey,
  ].map((actionPath) => ({
    path: actionPath,
    element: <WelcomePage />,
    loader: createActionLoader(actionService),
  }));
};
