import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
  RegisterOptions,
} from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";
import { TFunction } from "i18next";

interface IProps<R extends FieldValues> {
  name: Path<R>;
  defaultValue?: PathValue<R, Path<R>>;
  control: Control<R>;
  rules?: Omit<
    RegisterOptions<R, any>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
}

export const Input = <R extends FieldValues>({
  name,
  label,
  control,
  rules,
  defaultValue,
  ...props
}: IProps<R> & TextFieldProps) => {
  const { t: tDefault } = useTranslation("translation", { keyPrefix: "forms" });

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState }) => {
        const errorProps = fieldState.error
          ? {
              error: true,
              helperText: isEmpty(fieldState.error.message)
                ? tDefault(`default.${fieldState.error.type}`)
                : fieldState.error.message
            }
          : {};

        return (
          <TextField
            {...props}
            {...field}
            label={label}
            required={!!rules?.required}
            defaultValue={defaultValue}
            fullWidth
            {...errorProps}
          />
        );
      }}
    />
  );
};
