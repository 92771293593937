import { FC } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import {
  AppBar,
  Toolbar,
  Box,
  Typography,
  IconButton,
  styled,
  useMediaQuery,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import { SUPPORT_FORM_URL } from "../../../domain/specs/support";
import { getIssuerConfigService } from "../../../diContainer/getDependencies";
import LanguageMenu from "./LanguageMenu";
import LogOut from "./LogOut";

const StyledLink = styled(Link)(({ theme }) => ({
  padding: "1rem 0",
  textDecoration: "underline",
  color: "inherit",
  fontWeight: 500,
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

const StyledNavLink = styled(NavLink)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "0.5rem",
  padding: "1rem 0",
  color: "inherit",
  textDecoration: "none",
  cursor: "pointer",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

interface IProps {
  heading?: string;
  showMenu?: boolean;
}

const Header: FC<IProps> = ({ heading, showMenu }) => {
  const { t } = useTranslation("translation", { keyPrefix: "header" });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const issuerConfigService = getIssuerConfigService();

  const issuerSysName = issuerConfigService?.getIssuer();
  const termsAndConditionsLink = issuerConfigService?.getTermsLink();

  return (
    <AppBar sx={{ p: 0 }}>
      <Toolbar>
        {isMobile && (
          <Typography variant="h5" component="h5" sx={{ flexGrow: 1 }}>
            {heading}
          </Typography>
        )}

        {showMenu && (
          <nav>
            <Box component="ul" display="flex" alignItems="center" gap={3}>
              {isMobile ? (
                <li>
                  <IconButton
                    color="inherit"
                    component={NavLink}
                    to={`/${issuerSysName}/settings`}
                    sx={{ padding: "1rem 0" }}
                  >
                    <SettingsIcon fontSize="large" />
                  </IconButton>
                </li>
              ) : (
                <>
                  <li>
                    <StyledNavLink to={`/${issuerSysName}/settings/profile`}>
                      <SettingsIcon />
                      {t("settings")}
                    </StyledNavLink>
                  </li>
                  <li>
                    <StyledLink to={termsAndConditionsLink} target="_blank">
                      {t("terms")}
                    </StyledLink>
                  </li>
                  <li>
                    <StyledLink to={SUPPORT_FORM_URL} target="_blank">
                      {t("help")}
                    </StyledLink>
                  </li>
                  <li>
                    <LanguageMenu isDropdown />
                  </li>
                  <li>
                    <LogOut />
                  </li>
                </>
              )}
            </Box>
          </nav>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
