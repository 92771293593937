import { useTranslation } from "react-i18next";
import SettingsLayout from "../../components/layouts/SettingsLayout";
import ProfileSettingsCard from "../../components/ProfileSettings/ProfileSettingsCard";
import ProfilePhoneForm from "../../components/ProfileSettings/ProfilePhoneForm";

export const PhoneSettingPage = () => {
  const { t } = useTranslation("translation");

  return (
    <SettingsLayout title={t("header.profileInfo") ?? ""}>
      <ProfileSettingsCard
        title={t("profile.form.updatePhoneTitle") ?? ""}
        backUrl="/settings/profile"
      >
        <ProfilePhoneForm />
      </ProfileSettingsCard>
    </SettingsLayout>
  );
};

export default PhoneSettingPage;
