import {
  IGetBonusesResponseAttributes,
  IGetCardInfoResponseAttributes,
  IPassDTO,
} from "@roketus/loyalty-end-user-js-sdk";
import { ICardInfo } from "../../boundary/IProfileService";
import { toBarcodeEntity } from "./toBarcodeEntity";

export const toCardInfoEntity = (
  card: IGetCardInfoResponseAttributes,
  passData: IPassDTO,
  bonusesData: IGetBonusesResponseAttributes
): ICardInfo | null => {
  if (!card) return null;

  const barcodeInfo = toBarcodeEntity(passData, card?.CardNumber);
  const companyName = passData?.PassBack?.find(
    (item) => item.Key === "company_name"
  )?.Value;

  return {
    barcodeInfo,
    fullName: card?.NameOnCard,
    discount: card?.BonusPercent,
    cardNumber: card?.CardNumber,
    level: card?.Level,
    bonusBalance:
      bonusesData?.totalActive.UAH ||
      bonusesData?.totalActive.USD ||
      bonusesData?.totalActive.EUR ||
      0,
    organizationName: passData?.PassInfo?.OrganizationName,
    description: passData?.PassInfo?.Description,
    logoId: passData?.PassStyle?.LogoUrl,
    logoText: passData?.PassInfo?.LogoText,
    stripId: passData?.PassStyle?.StripUrl,
    thumbId: passData?.PassStyle?.ThumbnailUrl,
    backgroundColor: passData?.PassStyle?.Background,
    textColor: passData?.PassStyle?.Foreground,
    labelColor: passData?.PassStyle?.Label,
    companyName,
  };
};
